<template>
  <div>
    <div class="materialDetailBox">
      <div class="materialDetailHeader">
        <BreadCrumbs :breadList="breadList"></BreadCrumbs>
        <MiniSearch searchType="贴图"></MiniSearch>
      </div>
      <div class="materialDetailContent">
        <div class="contentLeft">
          <DetailImgShow
            :srcList="[detailInfo.pic_str]"
            type="chartlet"
          ></DetailImgShow>
        </div>
				
        <div class="contentRight">
					
          <div class="rightTop">
            <DetailInfo
              :authorInfo="authorInfo"
              :fid="2205"
              :detailId="detailId"
              @downloadFun="downloadFun"
              @getYunCodeFun="getYunCodeFun"
              :info="detailInfo"
              type="3"
            ></DetailInfo>
						
						<div class="free" @click="freeReceive">
							<img src="../../assets/image/free.gif" alt="">
						</div>
						
          </div>
					
          <div class="rightBottom">
            <AuthorMore
              :authorInfo="authorInfo"
              :authorOther="authorOther.length&&authorOther.slice(0, 3)"
              type="chartlet"
            ></AuthorMore>
          </div>
        </div>
      </div>
    </div>
    <div class="recommendBox ">
      <p>相关推荐</p>
			<chartlet-list
				ref="list"
				:colNum = "tabsActiveNmae"
				:chartletObj="chartletObj"
				:loading="loading"
				:listStatus="listStatus"
				@nextPage="nextPage"
			></chartlet-list>
    </div>
  </div>
</template>

<script>
import MiniSearch from "@/components/MiniSearch";
import DetailImgShow from "@/components/DetailImgShow";
import DetailInfo from "@/components/DetailInfo";
import AuthorMore from "@/components/AuthorMore";
import BreadCrumbs from "@/components/BreadCrumbs";
import chartletList from "./componts/chartletList.vue"
import {
	
  getAuthorInfo,
  // getMaterialCanDown,
	getPictureCanDown,
	
  pictureGetDetail,
	
  getPictureRelatedPic,
  getPictureCateNav,
	checkDownTimes
} from "../../Api";
import { getToken } from "@/utils/auth";
import { mapGetters } from "vuex";

export default {
  name: "SuDetail",
  components: {
    MiniSearch,
    DetailImgShow,
    DetailInfo,
    AuthorMore,
    BreadCrumbs,
		chartletList
  },
  data() {
    return {
			tabsActiveNmae: "123",
      downloadUrl: "",
      detailId: "",
      detailInfo: {
        preview: [],
      },
      breadList: [],
      authorInfo: {},
      recommend: [],
      authorOther: [],
      payObj: {
        payStatus: "noPay",
        isTopUp: false,
      },
      chartletObj: {
				total: 0,
				per_page: 20,
				current_page: 0,
				data: [],
			},
			loading: false,
			getListParams: {
				id: this.$route.query.detailId,
				page: 0,
			},
			listStatus: {
				isData: true,
				isNull: false,
			},
    };
  },
  computed: {
    ...mapGetters([
      "isLogin",
      "balance", // 知币余额
			"recommendList"
    ]),
  },
  created() {
    this.detailId = this.$route.query.detailId;
    this.initData();
  },
  methods: {
    initData() {
      Promise.all([
        this.getDetailInfo(),
        this.getAuthor(),
        // this.getRem(),
        this.getCateNav(),
      ]).then(() => {});
    },
		// 免费领知币弹窗
		freeReceive() {
			this.$picturePop().show()
		},
    //面包屑
    getCateNav() {
			let params = {
				id: this.detailId,
				fid: 2205
			}
      new Promise((resolve) => {
        getPictureCateNav(params).then((res) => {
          this.breadList = res.data;
          resolve();
        });
      });
    },
    //获取详情
    getDetailInfo() {
      return new Promise((resolve, reject) => {
        pictureGetDetail({id:this.detailId,token:getToken()})
          .then((res) => {
            const { data } = res;
            this.detailInfo = data;
            document.title = res.data.title + " - 贴图 - 知设网";
            resolve();
          })
          .catch((error) => {
            console.log("错误");
            reject(error);
          });
      });
    },
    // 获取作者信息
    getAuthor() {
			return new Promise((resolve, reject) => {
				//  作者信息
				getAuthorInfo({ id: this.detailId, fid: 2205})
					.then((res) => {
						this.authorInfo = res.data;
						
						getPictureRelatedPic({ uid: res.data.uid})
							.then((res) => {
								this.authorOther = res.data;
								resolve();
							})
							.catch((error) => {
								console.log("错误");
								reject(error);
							});
							
					})
					.catch((error) => {
						console.log("错误");
						reject(error);
					});
			});
    },
    // 获取提取码
    getYunCodeFun() {
      //判断是否登录
      this.$login({ isRouter: false }).then((res) => {
        const { isLogin } = res;
        if (isLogin) {
          this.getCanDown().then((canDowRes) => {
            if (canDowRes === "appreciate") {
              const info = { ...this.detailInfo };
              if (info.is_vip == 1) {
                info.price = (info.price * 0.8).toFixed(1);
              }
              const data = {
                //传递给函数式组件的值（慎用哦）
                userToken: getToken(),
                detailId: this.detailId, // 项目id
                balance: this.balance, // 知币余额
                projectInfo: info, // 项目详情
                authorUid: this.authorInfo.uid, // 作者uid
                title: "下载贴图",
                titleDec: "贴图名称",
								fid: 2205
              };
							
              this.payObj = this.$payPop({ data: data }); // 支付弹窗
              this.payObj.show();
            }
          });
        }
      });
    },
    //下载
    downloadFun(shareId) {
      this.$login({ isRouter: false }).then((res) => {
        const { isLogin } = res;
        if (isLogin) {
          this.getCanDown(shareId).then((canDowRes) => {
            if (canDowRes === "appreciate") {
              const info = { ...this.detailInfo };
              if (info.is_vip == 1) {
                info.price = (info.price * 0.8).toFixed(1);
              }
              const data = {
                //传递给函数式组件的值（慎用哦）
                userToken: getToken(),
                detailId: this.detailId, // 项目id
                balance: this.balance, // 知币余额
                projectInfo: info, // 项目详情
                authorUid: this.authorInfo.uid, // 作者uid
                title: "下载贴图",
                titleDec: "贴图名称",
								fid: 2205
              };
              this.payObj = this.$payPop({ data: data }); // 支付弹窗
              this.payObj.show();
            } else if (canDowRes === "down") {
							checkDownTimes({token: getToken(), id: this.detailId}).then((res) => {
								if (res.data&&res.data.error == 0) {
									let downloadUrl =
										this.$ROOT_DIR +
										"/web/Picture/down?id=" +
										this.detailId +
										"&token="+getToken()
									if (shareId) {
										downloadUrl = downloadUrl +"&share_id="+encodeURIComponent(shareId);
									}
									let link = document.createElement("a");
									link.style.display = "none";
									link.href = downloadUrl;
									document.body.appendChild(link);
									link.click();
								} else if (res.data&&res.data.error == -1) {
									this.$tooltipPop().show(1, res.data.msg)
								} else if (res.data&&res.data.error == -2) {
									this.$tooltipPop().show(2, res.data.msg)
								} else {
									this.$tooltipPop().show(0, res.data.msg)
								}
							}).catch((err) => {
								console.log(err);
							});
            }
          });
        }
      });
    },
    //判断是否需要支付购买
    getCanDown(shareId) {
      return new Promise((resolve) => {
        const params = { token: getToken(), id: this.detailId, share_id: "" };
				if (shareId) {
					params.share_id = decodeURIComponent(shareId)
				}
        getPictureCanDown(params).then((res) => {
          const canDownStatus = res.data.action;
          resolve(canDownStatus);
        });
      });
    },
		// 下一页
		nextPage() {
			this.getListParams.page += 1;
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.listStatus.isNull = false;
			this.listStatus.isData = true;
			if ( this.getListParams.page == 1 ) {
				this.$refs.list&&this.$refs.list.clear();
			}
			
			getPictureRelatedPic(this.getListParams)
				.then((res) => {
					this.loading = false;
					let data = res.data;
					this.chartletObj = data
					this.author = data.author
					this.total = data.total
					
					if (data.total == 0) {
						this.$refs.list.clear();
						this.listStatus.isNull = true;
					} else if (data.current_page == data.last_page) {
						this.listStatus.isData = false;
					}
				})
				.catch((error) => {
					console.log("错误", error);
				});
		},
  },
  watch: {
    "payObj.payStatus"(val) {
      // 监听支付弹窗中是否支付成功
      if (val === "paySuccess") {
        this.initData();
      }
    },
    "payObj.isTopUp"(val) {
      // 监听支付弹窗中是否支付成功
      if (val) {
        this.$topUpPop({ data: { balance: this.balance } });
      }
    },
    isLogin: function () {
      // 登录状态发生改变从新加载数据
      this.initData();
    },
		getListParams: {
			handler: function () {
				this.getList()
			},
			deep: true,
		},
  },
};
</script>

<style scoped>
.recommendBox {
  width: 100%;
	overflow: hidden;
  min-width: 1400px;
  padding: 0.4rem 1.2rem 0.32rem;
	background-color: #ffffff;
	text-align: center;
}
.recommendBox>>>.vue-waterfall {
	overflow: hidden;
}
.recommendBox>p{
	font-size: 0.3rem;
	font-weight: bold;
}
.rightBottom {
  margin-top: 0.1rem;
  width: 100%;
  background: #ffffff;
  border-radius: 0.04rem;
  flex: 1;
  overflow: hidden;
}

.rightTop {
  height: 6.91rem;
  width: 100%;
  background: #ffffff;
  border-radius: 0.04rem;
	position: relative;
}

.free {
	position: absolute;
	top: 0;
	right: -0.75rem;
	cursor: pointer;
}
.free img {
	width: 0.55rem;
	/* height: 1.875rem; */
}

.contentLeft {
	width: 9.8rem;
  /* flex: 1; */
  margin-right: 0.2rem;
  overflow: hidden;
  background: #ffffff;
  padding: 0.3rem 0.5rem;
}

.contentRight {
  width: 4rem;
  display: flex;
  flex-direction: column;
}

.materialDetailContent {
	width: 14rem;
	margin: 0 auto;
  display: flex;
  height: 9.05rem;
}

.materialDetailBox {
  width: 100%;
  min-width: 1400px;
  background: #f7f6fb;
  padding: 0 2.8rem 0.4rem;
}

.materialDetailHeader {
	width: 14rem;
	margin: 0 auto;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>